<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ALLOCATION OF FUND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form2" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md3>
            <v-select
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              @change="selected_category"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-if="!is_generated"
              v-model="total_fund"
              class="mx-2"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              label="Total Fund"
              type="number"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="total_fund"
              class="mx-2"
              dense
              outlined
              label="Total Fund"
              readonly
            ></v-text-field>
          </v-flex>

          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12"
                 v-if="(employee_id===1 || employee_id===2)&& !is_generated && !is_approved">
            <v-btn
              class="w-full"
              color="success"
              @click="save_fund"
              v-if="!saving_data"
            >
              Save Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" md="12"
                 v-if="(employee_id===1 || employee_id===2) && is_generated&& total_percentage_raw==='100.000' && !is_approved">
            <v-btn
              class="w-full"
              color="info"
              @click="approved_allocation"
              v-if="!saving_data"
            >
              Approved Allocation of Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5"
                  v-if="is_generated && total_percentage_raw<100">
          <v-flex xs12 md3>
            <v-select
              v-model="type_of_bank"
              class="mx-2"
              dense
              outlined
              label="Purpose"
              :items="['OTHER ALLOCATION','TRUST FUND','EXPENSE','RESERVE FUND','INVESTMENT','REMITTANCE']"
              @change="selected_type_of_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3 v-if="type_of_bank==='OTHER ALLOCATION'">
            <v-select
              v-model="category_id_2"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              @change="selected_category2"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center mx-2 mt-5"
                  v-if="is_generated && total_percentage_raw<100">
          <v-flex xs12 md3>
            <v-select
              v-model="bank_id"
              class="mx-2"
              dense
              outlined
              label="Bank Code"
              :items="bank_items"
              item-value="id"
              item-text="bank_code"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocation_percentage"
              class="mx-2"
              dense
              outlined
              label="Allocation Percentage"
              type="number"
              @keyup="computation_allocated"
              @change="computation_allocated"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md3>
            <v-text-field
              v-model="allocated_fund"
              class="mx-2"
              dense
              outlined
              label="Allocated Fund"
              readonly
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2)">
            <v-btn
              class="w-full"
              color="info"
              @click="save_fund_allocated"
              v-if="!saving_data"
            >
              Save Allocated Fund
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-layout>
      </v-form>
      <v-data-table class="mt-4" dense :headers="headers" :items="data_items" hide-default-footer
                    disable-pagination
                    :sort-by="['type_of_bank','bank_depository.bank_code']">
        <template v-slot:item.allocated_fund="{ item }">
          <tr>
            <td>
              {{ formatPrice(item.allocated_fund) }}
            </td>
          </tr>
        </template>

        <template v-slot:item.action="{ item }">
          <tr>
            <td v-if="item.id>0 && !is_approved">
              <v-icon class="mr-2" color="error" @click="delete_fund(item)">
                {{ icons.mdiDelete }}
              </v-icon>
              <v-icon class="mr-2" color="info" @click="edit(item)">
                {{ icons.mdiPencil }}
              </v-icon>
            </td>
            <td v-if="item.id>0 && is_approved">
              <v-icon class="mr-2" color="info" @click="add_schedule(item)">
                {{ icons.mdiPlus }}
              </v-icon>
            </td>
            <td v-if="item.id>0 && is_approved && item.schedule_data.length>0">
              <v-data-table class="mt-4" dense :headers="headers2" :items="item.schedule_data" hide-default-footer
                            disable-pagination
                            :sort-by="['date',]">
                </v-data-table>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_edit" persistent max-width="60%">
      <v-card>
        <v-card-text>
          <v-form ref="form" class="multi-col-validation mx-2 mt-5">
            <v-layout row wrap class="align-center" v-if="is_generated">
              <v-flex xs12 md3>
                <v-text-field
                  v-model="selected_data.type_of_bank"
                  class="mx-2"
                  dense
                  outlined
                  label="Purpose"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
              <v-flex xs12 md3>
                <v-text-field
                  v-model="selected_data.bank_depository.bank_code"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank Code"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="allocation_percentage"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocation Percentage"
                  type="number"
                  @keyup="computation_allocated"
                  @change="computation_allocated"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="allocated_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocated Fund"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2)">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="update_fund_allocated"
                  v-if="!saving_data"
                >
                  Update Changes
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn class="mt-4" color="error" @click="is_edit = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_add_schedule" persistent max-width="60%">
      <v-card>
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation mx-2 mt-5">
            <v-layout row wrap class="align-center mx-2 mt-5" v-if="is_generated">
              <v-flex xs12 md3>
                <v-text-field
                  v-model="schedule_date"
                  class="mx-2"
                  dense
                  outlined
                  label="Schedule Date"
                  type="date"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-model="schedule_amount"
                  class="mx-2"
                  dense
                  outlined
                  label="Schedule Amount"
                  type="number"
                ></v-text-field>
              </v-flex>
              <v-col cols="12" md="12" v-if="(employee_id===1 || employee_id===2)">
                <v-btn
                  class="w-full"
                  color="primary"
                  @click="add_schedule_data"
                  v-if="!saving_data"
                >
                  Add Schedule
                </v-btn>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
      <v-btn class="mt-4" color="error" @click="is_add_schedule = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_edit: false,
      is_generated: false,
      is_approved: false,
      is_add_schedule: false,
      saving_data: false,
      alert: false,
      alert_customize: false,
      alert_message: '',
      alert_message_customize: '',

      selected_data: {type_of_bank: '', bank_depository: []},

      schedule_date: '',
      schedule_amount: '',
      month_of_id: '',
      month_of_items: [],
      category_id: '',
      category_id_2: '',
      category_items: [],
      bank_id: '',
      bank_items: [],
      total_fund: '0',
      total_fund_raw: 0,
      total_percentage_raw: 0,
      allocation_id: 0,
      type_of_bank: '',
      allocation_percentage: '',
      allocated_fund: '',
      allocated_fund_raw: '',
      headers: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Bank Code', value: 'bank_depository.bank_code', sortable: true},
        {text: 'Type of Bank', value: 'type_of_bank', sortable: true},
        {text: 'Percentage', value: 'allocation_percentage', sortable: true},
        {text: 'Allocated Fund', value: 'allocated_fund', sortable: true},
        {text: 'Action', value: 'action', sortable: true},
      ],
      data_items: [],
      headers2: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Date', value: 'date', sortable: true},
        {text: 'Amount', value: 'amount', sortable: true},
      ],
      data_items2: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiDelete,
          mdiClose,
          mdiPencil,
          mdiPlus,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee',]),
      ...mapActions('bank_depository', ['bank_depository_type_of_bank',]),
      ...mapActions('allocation_of_funds', ['create_allocation_of_fund', 'initialize_allocation_of_fund', 'approved_allocation_of_fund']),
      ...mapActions('allocation_of_fund_data', ['create_allocation_of_fund_data', 'update_allocation_of_fund_data', 'delete_allocation_of_fund_data']),
      ...mapActions('allocation_of_fund_data_schedule', ['create_allocation_of_fund_schedule']),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.month_of_items = response.data[0].month_of
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id)+1,
              month_of: moment(this.month_of_items[0].month_of,'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id)+1,
              month_of: moment(this.month_of_items[0].month_of,'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.month_of_items.splice(0, 0, {
              id: parseInt(this.month_of_items[0].id)+1,
              month_of: moment(this.month_of_items[0].month_of,'MMMM YYYY').add(1, 'M').format('MMMM YYYY')
            })
            this.category_items = response.data[0].category
            this.category_items.splice(0, 1)
          })
          .catch(error => {
            console.log(error)
          })
      },
      computation_allocated() {
        this.allocated_fund_raw = (parseFloat(this.allocation_percentage) / 100) * parseFloat(this.total_fund_raw)
        this.allocated_fund = this.formatPrice(this.allocated_fund_raw)
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_category() {
        this.is_generated = false
        this.is_approved = false
        this.category_id_2 = ''
        this.allocation_id = 0
        this.data_items =[]
        const data = new FormData()
        data.append('category_id', this.category_id);
        data.append('month_of_id', this.month_of_id);
        this.initialize_allocation_of_fund(data)
          .then(response => {
            if (response.data.length > 0) {
              this.alert = false
              this.is_generated = true
              this.is_approved = response.data[0].is_approved
              this.allocation_id = response.data[0].id
              this.total_fund_raw = response.data[0].total_fund
              this.total_fund = this.formatPrice(response.data[0].total_fund)
              var arr = []
              var total_percentage = 0;
              var total_allocated = 0;
              response.data[0].allocation_data.forEach(function (item) {
                total_percentage += parseFloat(item.allocation_percentage)
                total_allocated += parseFloat(item.allocated_fund)
                arr.push({
                  id: item.id,
                  bank_depository: item.bank_depository,
                  type_of_bank: item.type_of_bank,
                  allocation_percentage: item.allocation_percentage,
                  allocated_fund: item.allocated_fund,
                  schedule_data: item.schedule_data,
                })
              })
              arr.push({
                id: 0,
                bank_depository: {bank_code: '*****TOTAL*****'},
                type_of_bank: '**********',
                allocation_percentage: total_percentage.toFixed(3),
                allocated_fund: total_allocated,
                schedule_data: [],
              })
              this.total_percentage_raw = total_percentage.toFixed(3)
              this.data_items = arr
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_type_of_bank() {
        this.bank_items = []
        const data = new FormData()
        data.append('category_id', this.type_of_bank === 'OTHER ALLOCATION' ? this.category_id_2 : this.category_id);
        data.append('type_of_bank', this.type_of_bank);
        await this.bank_depository_type_of_bank(data)
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_category2() {
        this.bank_items = []
        this.alert = false
        if (this.category_id === this.category_id_2) {
          this.alert = true
          this.alert_message = 'Please select other Category'
          this.saving_data = false
        } else {
          this.selected_type_of_bank()
        }
      },
      delete_fund(item) {
        if (confirm('PROCEED?')) {
          const data = new FormData()
          data.append('id', item.id);
          this.delete_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      async edit(item) {
        this.selected_data = item
        this.type_of_bank = await item.type_of_bank
        this.bank_id = await item.bank_depository.id
        await this.selected_type_of_bank
        this.allocation_percentage = await item.allocation_percentage
        this.allocated_fund = await item.allocated_fund
        this.is_edit = true
      },
      async add_schedule(item) {
        this.selected_data = item
        this.type_of_bank = await item.type_of_bank
        this.bank_id = await item.bank_depository.id
        await this.selected_type_of_bank
        this.allocation_percentage = await item.allocation_percentage
        this.allocated_fund = await item.allocated_fund
        this.is_add_schedule = true
      },
      save_fund() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('month_of_id', this.month_of_id);
          data.append('category_id', this.category_id);
          data.append('total_fund', this.total_fund);
          this.create_allocation_of_fund(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      save_fund_allocated() {
        this.saving_data = true
        this.alert = false
        var cat = this.category_id
        if (this.$refs.form2.validate()) {
          const data = new FormData()
          data.append('allocation_id', this.allocation_id);
          data.append('bank_id', this.bank_id);
          data.append('type_of_bank', this.type_of_bank);
          data.append('allocation_percentage', this.allocation_percentage);
          data.append('allocated_fund', this.allocated_fund_raw);
          this.create_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      update_fund_allocated() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('allocation_id', this.allocation_id);
          data.append('id', this.selected_data.id);
          data.append('allocation_percentage', this.allocation_percentage);
          data.append('allocated_fund', this.allocated_fund_raw);
          this.update_allocation_of_fund_data(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      approved_allocation() {
        this.saving_data = true
        this.alert = false
        const data = new FormData()
        data.append('allocation_id', this.allocation_id);
        this.approved_allocation_of_fund(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            this.selected_category()
          })
          .catch(error => {
            console.log(error)
          })
      },
      add_schedule_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form3.validate()) {
          const data = new FormData()
          data.append('fund_data_id', this.selected_data.id);
          data.append('date', this.schedule_date);
          data.append('amount', this.schedule_amount);
          this.create_allocation_of_fund_schedule(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.selected_category()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
    }
  }
</script>
